import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { t, use } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const SubjectIndexPage = observer(() => {
  const { rootStore, academicStore, teacherStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetSubj, setTargetSubj] = useState<any>();
  const [subjCode, setSubjCode] = useState<string>("");
  const [subjDescription, setSubjDescription] = useState<string>("");
  const [yearLevelId, setYearLevelId] = useState<string>("0");
  const [searchKey, setSearchKey] = useState<string>("");
  const [searchTeacherKey, setSearchTeacherKey] = useState<string>("");
  const [teacherList, setTeacherList] = useState<number[]>([]);
  const [page, setPage] = useState<number>(1);
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);

  useEffect(() => {
    // if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT_SEARCH) == 0 ||
    //   ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT_SEARCH) > PAGE_SIZE) {
    //   academicStore.getSubjectListBySearchKey({ searchKey: "", take: PAGE_SIZE, skip: 0 });
    // }
    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) > PAGE_SIZE) {
      teacherStore.getTeacherProfileList(0, 0);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.YEAR_LEVEL) == 0) {
      academicStore.getYearLevelList();
    }
  }, []);

  const onChangYearLevel = (event: SelectChangeEvent) => {
    setYearLevelId(event.target.value as string);
  };

  const onActionSubject = () => {
    let req: any = {
      id: 0,
      subjectCode: subjCode,
      subjectDescription: subjDescription,
      collegeId: GetCollegeId(),
      yearLevelId,
    }
    if (targetSubj) {
      req.id = targetSubj.id;
      academicStore.updateSubject(req).then(() => {
        rootStore.notify(t('SUBJECT') + t('UPDATED_B'), 'success');
        academicStore.getSubjectListBySearchKey(searchKey, PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      });
    } else {
      academicStore.createSubject(req).then(() => {
        rootStore.notify(t('SUBJECT') + t('CREATED_B'), 'success');
        academicStore.getSubjectListBySearchKey(searchKey, PAGE_SIZE, 0);
        setPage(1)
        handleCloseModal();
      });
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = academicStore.subjectListBySearchKey.data.find(s => s.id == id);
      setTargetSubj(target);
      setSubjCode(target.subjectCode);
      setSubjDescription(target.subjectDescription);
      setYearLevelId(target.yearLevelId);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetSubj(null);
    setSubjCode("");
    setSubjDescription("");
    setYearLevelId("0");
    setTeacherList([]);
    setSearchTeacherKey("");
  }

  const handleDeleteModal = (id: number) => {
    let target = academicStore.subjectListBySearchKey.data.find(s => s.id == id);
    setTargetSubj(target);
    setShowDeleteModal(true);
  }

  useEffect(() => {
    const delayDebouncer = setTimeout(() => {
      academicStore.getSubjectListBySearchKey(searchKey, PAGE_SIZE, 0);
      setPage(1);
    }, 800);
    return () => clearTimeout(delayDebouncer);
  }, [searchKey]);

  return (
    <ContentLayout
      pageName={t('SUBJECT')}
      pageHeading={t('SUBJECT')}
      breadCrumb={[
        { label: t('SUBJECT') + t('SETTING_B'), to: "/academic/subject-module" },
        { label: t('OVERVIEW'), to: "" }
      ]}
      buttonLabel={t('ADD_A') + t('SUBJECT')}
      onClickAction={() => handleShowModal()}
    >
      <TextField
        value={searchKey}
        label={t('SEARCH_SUBJECT')}
        variant="outlined"
        sx={{ width: 300, background: 'white', marginBottom: 2 }}
        onChange={(value) => setSearchKey(value.target.value)}
        // onKeyUp={handleKeyPress}
        size="small"
      />
      <VmTable
        loading={academicStore.loadingSubject}
        thead={["ID", t('SUBJECT_CODE'), t('SUBJECT') + t('DESCRIPTION_B'), t('ACTION')]}
        page={page}
        paginationCount={academicStore.subjectListBySearchKey.totalCount == 0 ? 1 : academicStore.subjectListBySearchKey.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          academicStore.getSubjectListBySearchKey(searchKey, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        tbody={academicStore.subjectListBySearchKey.data.length > 0 &&
          academicStore.subjectListBySearchKey.data.map((subject, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`subject_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{subject.subjectCode}</td>
                <td className={TD_NORMAL}>{subject.subjectDescription}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(subject.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(subject.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetSubj ? t('UPDATE_A') + t('SUBJECT') : t('ADD_A') + t('SUBJECT')}
        buttonLabel={targetSubj ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetSubj ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionSubject}
        disabledCondition={academicStore.yearLevelList.length < 1 || subjCode === "" || subjDescription === "" || teacherList.length == 0}
        width={500}
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <TextField
              value={subjCode}
              label={t('SUBJECT_CODE')}
              variant="outlined"
              sx={{ width: '100%', marginBottom: 1.5 }}
              onChange={(value) => setSubjCode(value.target.value)}
            />
            <TextField
              value={subjDescription}
              label={t('DESCRIPTION')}
              variant="outlined"
              sx={{ width: '100%' }}
              rows={3}
              multiline
              onChange={(value) => setSubjDescription(value.target.value)}
            />
          </div>
          <div>
            <TextField
              value={searchTeacherKey}
              hiddenLabel
              variant="outlined"
              sx={{ width: '100%', background: 'white', marginBottom: 1, fontSize: 10 }}
              onChange={(value) => setSearchTeacherKey(value.target.value)}
              // onKeyUp={handleKeyPress}
              placeholder={t('SEARCH_TEACHER')}
              size="small"
            />
            <div className="border rounded-sm p-2">
              <Typography variant="body2">{t('SELECTED_TEACHER')} ({teacherList.length})</Typography>
              <Divider sx={{ marginY: 1 }} />
              <div className="max-h-96 overflow-auto">
                {teacherStore.teacherProfileList.data
                  .filter(t => (searchTeacherKey === "" ? t.id != 0 : (t.firstName + " " + t.lastName).toLowerCase().includes(searchTeacherKey.toLowerCase())))
                  .map((t: any, index: number) => (
                    <button
                      type="button"
                      className={`hover:bg-highlight hover:text-white w-full text-left px-1 rounded-md text-sm 
                ${teacherList.includes(t.id) && "bg-highlight text-white"}`}
                      key={`student_select_${index}`}
                      onClick={() => {
                        let tempIdList = teacherList;
                        if (tempIdList.includes(t.id)) {
                          let i = tempIdList.indexOf(t.id);
                          tempIdList.splice(i, 1);
                        } else {
                          tempIdList.push(t.id);
                        }
                        setTeacherList(tempIdList);
                        setRefreshDisplay([]);
                      }}
                    >
                      {t.firstName + " " + t.lastName}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>

        {/* <FormControl fullWidth>
          <InputLabel>{t('YEAR_LEVEL')}</InputLabel>
          {academicStore.yearLevelList.length > 0 &&
            <Select
              label={t('YEAR_LEVEL')}
              sx={{ width: '100%' }}
              value={yearLevelId}
              onChange={onChangYearLevel}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.yearLevelList.map((yl: any, index: number) => (
                <MenuItem key={`year_level_select_${index}`} value={yl.id}>Year {yl.yearLevelDescription}</MenuItem>
              ))}
            </Select>}
        </FormControl> */}
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => academicStore.deleteSubject(targetSubj.id).then(() => {
          rootStore.notify(t('SUBJECT') + t('DELETED_B'), 'success')
          handleCloseModal();
          academicStore.getSubjectListBySearchKey(searchKey, PAGE_SIZE, 0);
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_SUBJECT')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default SubjectIndexPage;
